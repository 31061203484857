import {IconButton, Navbar} from "@material-tailwind/react";
import React from "react";
import {IconType} from "react-icons/lib";
import {useLocation, useNavigate} from "react-router-dom";

export type BottomNavigationItem = {
    title: string;
    icon: IconType;
    path: string;
    isUserAction: boolean;
    isManualTimer: boolean;
}

export type BottomNavigationProps = {
    className: string;
    items: BottomNavigationItem[];
    showUserActions: boolean;
    isManualTimerEnabled: boolean;
}

export default function BottomNavigation(props: BottomNavigationProps) {
    const navigate = useNavigate();
    const location = useLocation();

    const matchedAnyPath = props.items.some(item => location.pathname.startsWith(item.path))

    if (!matchedAnyPath) {
        return null
    } else {
        return (
            <div className={props.className}>
                <div className="h-16"></div>
                <Navbar shadow={false}
                        className={`fixed left-0 right-0 bottom-0 mx-auto shadow-t-md px-4 py-3 rounded-b-none ${props.className}`}>
                    <div className="flex justify-evenly text-blue-gray-900">
                        {props.items.filter(i => props.showUserActions ? true : !i.isUserAction)
                            .filter(i => props.isManualTimerEnabled ? true : !i.isManualTimer)
                            .map(navItem =>
                                <div key={navItem.path} className="flex">
                                    <IconButton variant="text"
                                                color={location.pathname.startsWith(navItem.path) ? 'blue' : 'blue-gray'}
                                                onClick={() => navigate(navItem.path)}>
                                        <navItem.icon className="h-6 w-6"/>
                                    </IconButton>
                                </div>
                            )}
                    </div>
                </Navbar>
            </div>
        )
    }
}


