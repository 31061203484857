import React from 'react';
import MeasurePage from "./components/MeasurePage";
import GlobalScorePage from './components/GlobalScorePage';
import InvitationPage from './components/InvitationPage';
import PersonPage from './components/PersonPage';
import {Navigate} from 'react-router-dom';
import SaveTimerPage from './components/SaveTimerPage';
import QRPage from './components/QRPage';
import RedeemCodeFunnelSessionPage from './components/RedeemCodeFunnelSessionPage';
import RedeemInvitationPage from './components/RedeemInvitationPage';
import EnsurePerson from './components/EnsurePerson';
import PersonScorePage from './components/PersonScorePage';
import PreInvitationPage from './components/PreInvitationPage';
import SettingsPage from './components/SettingsPage';
import RedeemExternalPage from "./components/RedeemExternalPage";
import RedeemExternalOtherPage from "./components/RedeemExternalOtherPage";

const AppRoutes = [
    {
        index: true,
        path: '/',
        element: <Navigate to={'/score'} replace/>
    },
    {
        index: true,
        path: '/score',
        element: <GlobalScorePage/>
    },
    {
        path: '/linked',
        element: <Navigate to={'/score'} replace/>
    },
    {
        path: '/trichtern',
        element: <EnsurePerson><MeasurePage/></EnsurePerson>
    },
    {
        path: '/trichtern/save',
        element: <SaveTimerPage/>
    },
    {
        path: '/invite',
        element: <EnsurePerson><PreInvitationPage/></EnsurePerson>
    },
    {
        path: '/invite/camp',
        element: <EnsurePerson><InvitationPage/></EnsurePerson>
    },
    {
        path: '/redeeminvite',
        element: <RedeemInvitationPage/>
    },
    {
        path: '/person',
        element: <PersonPage/>
    },
    {
        path: '/me',
        element: <EnsurePerson><PersonScorePage/></EnsurePerson>
    },
    {
        path: '/redeem',
        element: <RedeemCodeFunnelSessionPage/>
    },
    {
        path: '/qr',
        element: <QRPage/>
    },
    {
        path: '/settings',
        element: <SettingsPage/>
    },
    {
        path: '/a',
        element: <RedeemExternalPage/>
    },
    {
        path: '/redeemExternal/other',
        element: <RedeemExternalOtherPage/>
    }
];

export default AppRoutes;
