import React, {ReactElement, useCallback, useContext} from 'react';
import BottomNavigation, {BottomNavigationItem} from './BottomNavigation';
import {MdOutlinePerson, MdOutlinePersonAdd, MdOutlineSportsBar, MdOutlineTimer} from 'react-icons/md'
import Header from './Header';
import {AuthContext} from '../contexts/AuthContext';

export function Layout(props: { isManualTimerEnabled: boolean, children: ReactElement }) {

    const [authContext] = useContext(AuthContext)

    const createNavItems = useCallback((): BottomNavigationItem[] => {
        return [
            {title: 'Score', icon: MdOutlineSportsBar, path: '/score', isUserAction: false, isManualTimer: false},
            {title: 'Mein', icon: MdOutlinePerson, path: '/me', isUserAction: false, isManualTimer: false},
            {title: 'Trichtern', icon: MdOutlineTimer, path: '/trichtern', isUserAction: true, isManualTimer: true},
            {title: 'Einladen', icon: MdOutlinePersonAdd, path: '/invite', isUserAction: true, isManualTimer: false}
        ];
    }, [])

    return (
        <div className='flex flex-col viewport-fill'>
            <Header className='grow-0 shrink basis-16'/>
            <div className='grow shrink-0 basis-0'>
                {props.children}
            </div>
            <BottomNavigation className='grow-0 shrink basis-16' items={createNavItems()}
                              showUserActions={authContext.currentRole !== undefined && authContext.currentRole !== null}
                              isManualTimerEnabled={props.isManualTimerEnabled || authContext.currentRole === "Owner"}/>
        </div>
    );
}
