import { Api } from "./Api";
import { SelectorItem } from "./components/SegmentedSelector";

export const AppName: string = "Deichbrand Trichtern";
export const EventStartDate = new Date('2024-07-17T12:00:00')

export const drinkCategoryItemsWithNone: SelectorItem[] = [
    { text: 'Alle', value: -1 },
    { text: '0,33l', value: 330 },
    { text: '0,5l', value: 500 },
    { text: '0,66l', value: 660 },
    { text: '1,00l', value: 1000 }
]

export const drinkCategoryItems: SelectorItem[] = [
    { text: '0,33l', value: 330 },
    { text: '0,5l', value: 500 },
    { text: '0,66l', value: 660 },
    { text: '1,00l', value: 1000 }
]

export const drinkSessionTypeSelectorItemsWithNone: SelectorItem[] = [
    { text: 'Alle', value: null },
    { text: 'Exen', value: Api.DrinkSessionType.Ex },
    { text: 'Dosenstechen', value: Api.DrinkSessionType.Canning },
    { text: 'Trichtern', value: Api.DrinkSessionType.Funneling }
]

export const drinkSessionTypeSelectorItems: SelectorItem[] = [
    { text: 'Exen', value: Api.DrinkSessionType.Ex },
    { text: 'Dosenstechen', value: Api.DrinkSessionType.Canning },
    { text: 'Trichtern', value: Api.DrinkSessionType.Funneling }
]

export const daySelectorItems: SelectorItem[] = [
    { text: 'Gesamt', value: -1 },
    { text: 'Mittwoch', value: 0 },
    { text: 'Donnerstag', value: 1 },
    { text: 'Freitag', value: 2 },
    { text: 'Samstag', value: 3 },
    { text: 'Sonntag', value: 4 }
]
