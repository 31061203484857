import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Api} from "../Api";
import ErrorInformation from "./ErrorInformation";
import {Button, Card, CardBody, CardFooter, Typography} from "@material-tailwind/react";
import DrinkSessionType = Api.DrinkSessionType;
import {ChoosePersonList} from './ConnectPerson'
import Person = Api.Person;
import {formatTime} from "../Util";

export default function RedeemExternalOtherPage() {
    const location = useLocation()
    const [loading, setLoading] = useState<boolean>()
    const [error, setError] = useState<Error | undefined>()
    const [personList, setPersonList] = useState<Api.PersonList>()
    const [selectedPerson, setSelectedPerson] = useState<Person | undefined>()
    const navigate = useNavigate()
    
    const loadPersons = async () => {
        const personList = await Api.GetAllPersons();
        setPersonList(personList);
    }

    const loadData = async () => {
        setLoading(true)
        try {
            await loadPersons()
        } catch (error) {
            console.log(error)
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    const saveForSelectedUser = async (person: Api.Person) => {
        setLoading(true)
        try {
            const token = await Api.CreateFunnelSessionToken()

            await Api.SaveFunnelSession({
                token: token,
                type: DrinkSessionType.Funneling,
                milliliters: location.state.selectedAmount,
                totalMilliseconds: location.state.timeMs,
                correctedMilliseconds: 0,
                recordedByPersonId: null,
                recordedExternally: true,
                uniqueId: location.state.uniqueId,
                drinkingPersonId: person.id
            })
            
            navigate('/score')
        } catch (error) {
            console.log(error)
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        loadData()
    }, [])

    if (error)
        return <ErrorInformation error={error} onRetry={() => loadPersons()}/>

    if (loading || personList === undefined) {
        return (
            <div className="flex justify-center items-center h-full">
                <div
                    className='w-8 h-8 mr-2 border-4 border-blue-400 border-t-transparent rounded-full animate-spin'></div>
            </div>
        )
    }

    return (
        <div className='flex flex-col justify-center items-center h-full p-4 pb-20'>
            <Card className='w-full max-h-full shadow-all'>
                <CardBody>
                    <Typography variant='h5' className='mb-2 text-center'>
                        {formatTime(location.state.timeMs)}s
                    </Typography>

                    <ChoosePersonList personList={personList} onPersonSelected={person => setSelectedPerson(person)}/>
                </CardBody>
            </Card>

            <Button className='m-0 fixed bottom-4 left-4 right-4'
                disabled={loading || selectedPerson === undefined}
                    onClick={() => saveForSelectedUser(selectedPerson)}>
                Speichern
            </Button>
        </div>
    )
}