import React, { useContext, useEffect, useState } from 'react';
import SegmentedSelector, { SelectorItem } from './SegmentedSelector';
import { PersonContext } from '../contexts/PersonContext';
import { EventStartDate, daySelectorItems, drinkCategoryItemsWithNone, drinkSessionTypeSelectorItemsWithNone } from '../Constants';
import { Api } from '../Api';
import ErrorInformation from './ErrorInformation';
import { Button, Card, CardBody, Chip, IconButton, List, ListItem, ListItemPrefix, Typography } from '@material-tailwind/react';
import { MdLocalDrink, MdTimer, MdSpeed, MdFastForward, MdNumbers, MdOutlineLink, MdLink, MdOpenInBrowser, MdOpenWith, MdOpenInNew, MdFastRewind } from 'react-icons/md';
import { formatLiters, formatMinutes, getMillilitersPerSecond, getColorForPlace } from '../Util';
import { StatEntry, StatHistoryEntryPersonDetails } from './StatEntry';

export default function GlobalScorePage() {

    const [personContext] = useContext(PersonContext)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<Error>()
    const [selectedDay, setSelectedDay] = useState<SelectorItem>()
    const [selectedDrinkType, setSelectedDrinkType] = useState<SelectorItem>()
    const [selectedDrinkCategory, setSelectedDrinkCategory] = useState<SelectorItem>()
    const [stats, setStats] = useState<Api.GlobalFunnelStats>()
    const [campLocation, setCampLocation] = useState<Api.Location>({ latitude: 0, longitude: 0 })

    const loadStats = async () => {
        try {
            setLoading(true)

            let resultDate: Date = null

            if (selectedDay !== undefined && selectedDay?.value >= 0) {
                resultDate = new Date(EventStartDate)
                resultDate.setDate(resultDate.getDate() + (selectedDay.value as number))
            }

            console.log('Requesting global stats for date: ' + resultDate)

            const stats = await Api.GetGlobalStats(
                resultDate,
                selectedDrinkType ? selectedDrinkType.value : null,
                selectedDrinkCategory ? selectedDrinkCategory.value : null)

            setError(undefined)
            setStats(stats)
        } catch (error) {
            setError(error)
        }

        try {
            setCampLocation(await Api.GetLocation())
        } catch (error) {
            setCampLocation({ latitude: 0, longitude: 0 })
            console.log(error)
        }

        setLoading(false)
    }

    useEffect(() => {
        loadStats()
    }, [selectedDay, selectedDrinkType, selectedDrinkCategory])

    if (loading) {
        return (
            <div className="flex justify-center items-center h-full">
                <div className='w-8 h-8 mr-2 border-4 border-blue-400 border-t-transparent rounded-full animate-spin'></div>
            </div>
        )
    } else if (stats) {

        const personsOrderedByAmount = [...stats.personStats].sort((a, b) => a.totalMilliliter - b.totalMilliliter).reverse()

        const personsOrderedBySpeed = stats.personStats
            .map(s => Object.assign(s, { person: s.person, milliliterPerSecond: getMillilitersPerSecond(s.totalMilliseconds, s.totalMilliliter) }))
            .sort((a, b) => a.milliliterPerSecond - b.milliliterPerSecond)
            .reverse()

        return (
            <div className='flex flex-col p-4 gap-4'>
                <Card>
                    <CardBody className='flex flex-col gap-2'>
                        <Typography variant='lead'>Filter</Typography>

                        <SegmentedSelector id='day_select_global' name='Tag' items={daySelectorItems} selectedItem={selectedDay} onSelect={item => setSelectedDay(item)} />
                        <SegmentedSelector id='type_select_global' name='Typ' items={drinkSessionTypeSelectorItemsWithNone} selectedItem={selectedDrinkType} onSelect={item => setSelectedDrinkType(item)} />
                        <SegmentedSelector id='cat_select_global' name='Größe' items={drinkCategoryItemsWithNone} selectedItem={selectedDrinkCategory} onSelect={item => setSelectedDrinkCategory(item)} />
                    </CardBody>
                </Card>

                <Card className="shadow-all">
                    <CardBody className='flex flex-col gap-2'>
                        <Typography variant='lead' className='mb-2'>Globale Statistik</Typography>
                        <StatEntry icon={MdLocalDrink} text={`${formatLiters(stats.totalMilliliters)} Liter in`} />
                        <StatEntry icon={MdTimer} text={`${formatMinutes(stats.totalMilliseconds)} Minuten vernichtet`} />
                        <StatEntry icon={MdSpeed} text={`Das entspricht ${getMillilitersPerSecond(stats.totalMilliseconds, stats.totalMilliliters)} ml/s`} />
                        <StatEntry icon={MdNumbers} text={`Insgesamt ${stats.historyFunnelSessions.length} mal ⌀ ${stats.averageMilliliters} ml`} />

                        {stats.fastestFunnelSession &&
                            <div className={`flex gap-4 items-center mt-4`}>
                                <div className='inline-flex items-center justify-center w-10 h-10 rounded-lg bg-yellow-600'>
                                    <MdFastForward size={25} />
                                </div>
                                <div className='flex flex-col'>
                                    <Typography variant='h6'>Schnellster Eintrag</Typography>
                                    <Typography>{stats.fastestFunnelSession.drinkingPerson?.name}</Typography>
                                    <Typography>{`${getMillilitersPerSecond(stats.fastestFunnelSession.totalMilliseconds, stats.fastestFunnelSession.milliliters)} ml/s bei ${formatLiters(stats.fastestFunnelSession.milliliters)} l`}</Typography>
                                </div>
                            </div>
                        }

                        {stats.slowestFunnelSession &&
                            <div className={`flex gap-4 items-center mt-4`}>
                                <div className='inline-flex items-center justify-center w-10 h-10 rounded-lg bg-red-200'>
                                    <MdFastRewind size={25} />
                                </div>
                                <div className='flex flex-col'>
                                    <Typography variant='h6'>Langsamster Eintrag</Typography>
                                    <Typography>{stats.slowestFunnelSession.drinkingPerson?.name}</Typography>
                                    <Typography>{`${getMillilitersPerSecond(stats.slowestFunnelSession.totalMilliseconds, stats.slowestFunnelSession.milliliters)} ml/s bei ${formatLiters(stats.slowestFunnelSession.milliliters)} l`}</Typography>
                                </div>
                            </div>
                        }
                    </CardBody>
                </Card>

                <Card>
                    <CardBody className='flex flex-col gap-2'>
                        <Typography variant='lead'>Wo?</Typography>
                        <div className='flex justify-center items-center gap-4'>
                            <Typography>Womo South</Typography>
                            <a href={`http://maps.apple.com/?ll=${campLocation?.latitude},${campLocation?.longitude}&dirflg=w`}>
                                <Button className='flex justify-center items-center' variant='outlined'>
                                    Maps
                                    <MdOpenInNew size={20} className='ml-2' />
                                </Button>
                            </a>
                        </div>
                    </CardBody>
                </Card>

                <Card className="shadow-all">
                    <CardBody className='flex flex-col gap-2'>
                        <Typography variant='lead' className='mb-2'>Rangliste (Menge)</Typography>

                        {personsOrderedByAmount.length === 0 &&
                            <Typography>Keine Einträge</Typography>
                        }

                        <List className='w-full'>
                            {personsOrderedByAmount.map((personStats: Api.GlobalPersonStats, index: number) =>
                                <ListItem key={personStats.person.id}>
                                    <ListItemPrefix>
                                        <div className={`inline-flex items-center justify-center w-10 h-10 rounded-full ${getColorForPlace(index)}`}>
                                            <span>{index + 1}.</span>
                                        </div>
                                    </ListItemPrefix>
                                    <div>
                                        <Typography variant="h6" color="blue-gray">
                                            {personStats.person.name}
                                        </Typography>
                                        <div className='flex flex-row items-center'>
                                            <Typography color="blue-gray">
                                                {formatLiters(personStats.totalMilliliter)} l
                                            </Typography>
                                            {personContext.loggedInPerson?.id === personStats.person.id &&
                                                <Chip value='Du' className='ml-4' />
                                            }
                                        </div>
                                    </div>
                                </ListItem>
                            )}
                        </List>
                    </CardBody>
                </Card>

                <Card className="shadow-all">
                    <CardBody className='flex flex-col gap-2'>
                        <Typography variant='lead' className='mb-2'>Rangliste (Geschwindigkeit)</Typography>

                        {personsOrderedBySpeed.length === 0 &&
                            <Typography>Keine Einträge</Typography>
                        }

                        <List className='w-full'>
                            {personsOrderedBySpeed.map((personStats: any, index: number) =>
                                <ListItem key={personStats.person.id}>
                                    <ListItemPrefix>
                                        <div className={`inline-flex items-center justify-center w-10 h-10 rounded-full ${getColorForPlace(index)}`}>
                                            <span>{index + 1}.</span>
                                        </div>
                                    </ListItemPrefix>
                                    <div>
                                        <Typography variant="h6" color="blue-gray">
                                            {personStats.person.name}
                                        </Typography>
                                        <div className='flex flex-row items-center'>
                                            <Typography color="blue-gray">
                                                {(personStats.milliliterPerSecond)} ml/s
                                            </Typography>
                                            {personContext.loggedInPerson?.id === personStats.person.id &&
                                                <Chip value='Du' className='ml-4' />
                                            }
                                        </div>
                                    </div>
                                </ListItem>
                            )}
                        </List>
                    </CardBody>
                </Card>

                <Card className="shadow-all">
                    <CardBody className='flex flex-col gap-2'>
                        <Typography variant='lead' className='mb-2'>Historie</Typography>
                        {stats.historyFunnelSessions.length > 0 &&
                            <List>
                                {stats.historyFunnelSessions.slice(0, 10).map(fs =>
                                    <StatHistoryEntryPersonDetails key={fs.id} fs={fs} />
                                )}
                                {stats.historyFunnelSessions.length > 10 &&
                                    <ListItem key='cont_item'>
                                        <div>
                                            <Typography variant='h6'>
                                                ...
                                            </Typography>
                                        </div>
                                    </ListItem>
                                }
                            </List>
                        }
                        {stats.historyFunnelSessions.length === 0 &&
                            <Typography>Keine Einträge</Typography>
                        }
                    </CardBody>
                </Card>
            </div>
        )
    } else if (error) {
        return (
            <ErrorInformation error={error}
                onRetry={() => loadStats()} />
        )
    }
}
