import {IconButton, ListItem, ListItemPrefix, ListItemSuffix, Typography} from "@material-tailwind/react";
import React from "react";
import {IconType} from "react-icons/lib";
import {formatLiters, getMillilitersPerSecond, formatTime, getIconForDrinkType} from "../Util";
import {Api} from "../Api";
import {MdOutlineDelete, MdOutlinePhonelinkRing, MdOutlinePhonelinkErase, MdOutlineTimer} from "react-icons/md";
import {IoStopwatchOutline} from "react-icons/io5";

export function StatEntry(props: { icon: IconType, text: string, className?: string | null }) {
    return (
        <div className={`flex gap-4 items-center ${props.className}`}>
            <div className='inline-flex items-center justify-center w-10 h-10 rounded-lg bg-gray-300'>
                <props.icon size={25}/>
            </div>
            <Typography>{props.text}</Typography>
        </div>
    )
}

export function StatHistoryEntry(props: { fs: Api.FunnelSession }) {

    const DrinkIconType = getIconForDrinkType(props.fs.type)

    return (
        <ListItem key={props.fs.id}>
            <ListItemPrefix>
                <div className='inline-flex items-center justify-center w-10 h-10 rounded-full bg-gray-300'>
                    <DrinkIconType size={25}/>
                </div>
            </ListItemPrefix>
            <div>
                <Typography variant='h6'>
                    {`${formatLiters(props.fs.milliliters)} Liter in ${formatTime(props.fs.totalMilliseconds)} s`}
                </Typography>
                <Typography>
                    {`${getMillilitersPerSecond(props.fs.totalMilliseconds, props.fs.milliliters)} ml/s - ${new Date(props.fs.timestamp).toLocaleDateString('de-DE', {
                        weekday: 'long',
                        hour: '2-digit',
                        minute: '2-digit'
                    })}`}
                </Typography>
            </div>
            <ListItemSuffix>
                {props.fs.recordedExternally ?
                    <MdOutlinePhonelinkRing className='h-5 w-5'/> : <MdOutlineTimer className='h-5 w-5'/>
                }
            </ListItemSuffix>
        </ListItem>
    )
}

export function StatHistoryEntryPersonDetails(props: {
    fs: Api.FunnelSession,
    onDelete?: (fs: Api.FunnelSession) => void
}) {

    if (props.fs.drinkingPerson === null)
        return null

    const DrinkIconType = getIconForDrinkType(props.fs.type)

    return (
        <ListItem key={props.fs.id}>
            <ListItemPrefix>
                <div className='inline-flex items-center justify-center w-10 h-10 rounded-full bg-gray-300'>
                    <DrinkIconType size={25}/>
                </div>
            </ListItemPrefix>
            <div>
                <Typography variant='h6'>
                    {`${formatLiters(props.fs.milliliters)} Liter in ${formatTime(props.fs.totalMilliseconds)} s`}
                </Typography>
                <Typography>
                    {`${getMillilitersPerSecond(props.fs.totalMilliseconds, props.fs.milliliters)} ml/s - ${new Date(props.fs.timestamp).toLocaleDateString('de-DE', {
                        weekday: 'long',
                        hour: '2-digit',
                        minute: '2-digit'
                    })}`}
                </Typography>
                <Typography>
                    {props.fs.drinkingPerson.name}
                </Typography>
            </div>
            <ListItemSuffix>
                {props.fs.recordedExternally ?
                    <MdOutlinePhonelinkRing className='h-5 w-5'/> : <MdOutlineTimer className='h-5 w-5'/>
                }
                {props.onDelete &&
                    <IconButton variant="text" color="blue-gray" onClick={() => props.onDelete(props.fs)}>
                        <MdOutlineDelete className="h-5 w-5"/>
                    </IconButton>
                }
            </ListItemSuffix>
        </ListItem>
    )
}