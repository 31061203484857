import React, {useContext, useEffect, useState} from "react";
import {PersonContext} from "../contexts/PersonContext";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Button, Card, CardBody, CardFooter, List, ListItem, ListItemPrefix, Typography} from "@material-tailwind/react";
import {formatTime, getInitials} from "../Util";
import {SegmentedSelectorA} from "./SegmentedSelector";
import {drinkCategories} from "./MeasurePage";
import ErrorInformation from "./ErrorInformation";
import {Api} from "../Api";
import DrinkSessionType = Api.DrinkSessionType;


export default function RedeemExternalPage() {

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<Error | undefined>()
    const [personContext] = useContext(PersonContext)
    const [searchParams] = useSearchParams()
    const [uniqueId, setUniqueId] = useState<string | undefined>()
    const [timeMs, setTimeMs] = useState<number | undefined>(undefined)
    const [selectedOptionPerson, setSelectedOptionPerson] = useState(0)
    const [selectedAmount, setSelectedAmount] = useState<number | undefined>()
    const navigate = useNavigate()

    const load = async () => {
        if (personContext.loggedInPerson === null) {
            const currentPath = window.location.pathname + window.location.search
            console.log(`Current path is ${currentPath}`)
            navigate(`/person?m=login-req&r=${btoa(currentPath)}`);
            return
        }

        try {
            const id = searchParams.get("i")
            const timeMs = Number(searchParams.get("t"))

            setTimeMs(timeMs)
            setUniqueId(id)

            setError(undefined)
        } catch (error) {
            console.log(error)
            setError(error)
        }

        setLoading(false)
    }

    const saveForCurrentUser = async () => {
        try {
            setLoading(true);
            const token = await Api.CreateFunnelSessionToken()

            await Api.SaveFunnelSession({
                token: token,
                type: DrinkSessionType.Funneling,
                milliliters: selectedAmount,
                totalMilliseconds: timeMs,
                correctedMilliseconds: 0,
                recordedByPersonId: null,
                recordedExternally: true,
                uniqueId: uniqueId,
                drinkingPersonId: null
            })

            navigate('/me')
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false);
        }
    }

    const chooseOtherUser = async () => {
        if (selectedAmount === undefined)
            return

        navigate('/redeemExternal/other', {state: {uniqueId: uniqueId, timeMs: timeMs, selectedAmount: selectedAmount}})
    }

    useEffect(() => {
        load()
    }, [])

    if (error)
        return (
            <ErrorInformation error={error}
                              onRetry={() => load()}/>
        )

    return <div className='flex flex-col justify-center items-center h-full p-4'>
        <Card className='w-full shadow-all'>
            <CardBody>
                <Typography variant='h5' className='mb-2 text-center'>
                    {formatTime(timeMs)}s
                </Typography>

                <List>
                    <ListItem selected={selectedOptionPerson === 0}
                              onClick={() => setSelectedOptionPerson(0)}>
                        <ListItemPrefix>
                            <div className='inline-flex items-center justify-center w-10 h-10 rounded-full bg-gray-300'>
                                <span>{getInitials(personContext.loggedInPerson?.name ?? 'X')}</span>
                            </div>
                        </ListItemPrefix>
                        <div>
                            <Typography variant="h6" color="blue-gray">
                                Für mich
                            </Typography>
                            <Typography variant="small" color="gray" className="font-normal">
                                {personContext.loggedInPerson?.name ?? ''}
                            </Typography>
                        </div>
                    </ListItem>
                    <ListItem selected={selectedOptionPerson === 1}
                              onClick={() => setSelectedOptionPerson(1)}>
                        <ListItemPrefix>
                            <div
                                className='inline-flex items-center justify-center w-10 h-10 rounded-full bg-gray-300'></div>
                        </ListItemPrefix>
                        <div>
                            <Typography variant="h6" color="blue-gray">
                                Für jemand anderen
                            </Typography>
                        </div>
                    </ListItem>
                </List>

                <hr className='my-6'/>

                <div className='m-4'>
                    <SegmentedSelectorA name='' id="redeem_amount" className='mb-4' items={drinkCategories}
                                        selectedItem={undefined} onSelect={item => {
                        setSelectedAmount(item.value as number)
                    }}/>
                </div>

            </CardBody>
            <CardFooter>
                <Button disabled={loading || selectedAmount === undefined}
                        onClick={() => selectedOptionPerson === 0 ? saveForCurrentUser() : chooseOtherUser()}>
                    <div
                        className={`inline-block w-3 h-3 mr-2 border-4 border-blue-200 border-t-transparent rounded-full animate-spin ${loading ? '' : 'hidden'}`}></div>
                    <span>{selectedOptionPerson === 0 ? 'Speichern' : 'Person auswählen'}</span>
                </Button>
            </CardFooter>
        </Card>
    </div>
}