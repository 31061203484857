import React, { useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";
import color from 'tailwindcss/colors';

export default function QRDisplay(props: { codeContent: string }) {

    if (props.codeContent === undefined)
        return

    return (
        <QRCode value={props.codeContent} className='w-full' fgColor={color.sky[600]} />
    )
}