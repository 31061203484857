import React, { useContext } from "react";
import { Button, IconButton, Navbar, Typography } from "@material-tailwind/react";
import { AppName } from "../Constants";
import AppLogo from './../images/trichtern.png';
import { MdLogout, MdSettings } from "react-icons/md";
import { PersonContext } from "../contexts/PersonContext";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";

export default function Header(props: { className: string }) {

    const [personContext] = useContext(PersonContext)
    const [authContext] = useContext(AuthContext)
    const navigate = useNavigate()

    return (
        <Navbar className={`mx-auto px-4 py-3 ${props.className}`}>
            <div className="flex items-start text-blue-gray-900">
                <div className="flex">
                    <img src={AppLogo} width={32} height={32} className="mr-2" />
                </div>
                <Typography
                    as="a"
                    href="#"
                    variant="h6"
                    className="mr-4 ml-2 py-1.5">
                    {AppName}
                </Typography>
                <div className="grow"></div>

                {authContext.currentRole === 'Owner' &&
                    <IconButton variant="text" color='blue-gray' onClick={() => navigate('/settings')}>
                        <MdSettings className="h-6 w-6" />
                    </IconButton>
                }
                {personContext.loggedInPerson !== null &&
                    <IconButton variant="text" color='blue-gray' onClick={() => navigate('/person')}>
                        <MdLogout className="h-6 w-6" />
                    </IconButton>
                }
            </div>
        </Navbar>
    );
}
