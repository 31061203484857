import { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { Api } from "../Api";
import React from "react";
import ErrorInformation from "./ErrorInformation";
import { PersonContext } from "../contexts/PersonContext";
import { Alert, Button } from "@material-tailwind/react";

export default function RedeemInvitationPage() {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<Error>()
    const [success, setSuccess] = useState(false)
    const [searchParams] = useSearchParams()
    const [personContext] = useContext(PersonContext)
    const navigate = useNavigate()

    const redeem = async () => {
        try {
            setLoading(true)

            const invitationBase64 = searchParams.get('inv')
            const invitationJson = atob(invitationBase64)
            const invitation: Api.Invitation = JSON.parse(invitationJson)

            await Api.RedeemInvitation(invitation)

            setError(undefined)
            setSuccess(true)
        } catch (error) {
            setError(error)
        }

        setLoading(false)
    }

    useEffect(() => {
        redeem()
    }, [])

    if (loading) {
        return (
            <div className="flex justify-center items-center h-full">
                <div className='w-8 h-8 mr-2 border-4 border-blue-400 border-t-transparent rounded-full animate-spin'></div>
            </div>
        )
    } else if (success) {
        if (personContext.loggedInPerson === null) {
            return <Navigate to={`/person?m=success-auth`} replace />
        } else {
            return (
                <div className="flex flex-col gap-2 items-center justify-center h-full p-4">
                    <Alert color="green">Du hast dich erfolgreich autorisiert! ({personContext.loggedInPerson?.name})</Alert>
                    <Button variant="text" onClick={() => navigate('/score')}>Zurück zur Startseite</Button>
                </div>
            )
        }
    } else if (error) {
        return (
            <ErrorInformation error={error} onRetry={() => redeem()} />
        )
    } else {
        return <div>Unbekannter Error</div>
    }
}