import { Button, Typography } from "@material-tailwind/react";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function ErrorInformation(props: { error: Error, onRetry?: () => void, message?: string | null }) {

    const navigate = useNavigate()

    return (
        <div className='flex flex-col items-center w-full p-4 gap-4'>
            <Typography variant='h5'>Es ist ein Fehler aufgetreten</Typography>
            <Typography variant='paragraph' className='text-center'>{props.message ?? props.error.message}</Typography>
            <Button variant='outlined' onClick={() => navigate('/score')}>Zur Startseite</Button>
            {props.onRetry && <Button onClick={() => props.onRetry()}>Erneut versuchen</Button>}
        </div>
    )
}