import { Select, Option, Tab, Tabs, TabsBody, TabsHeader } from "@material-tailwind/react";
import React, { useState } from "react";

export type SelectorProps = {
    className?: string | null,
    id: string,
    name: string,
    items: SelectorItem[],
    selectedItem: SelectorItem,
    onSelect: (item: SelectorItem) => void
}

export type SelectorItem = {
    text: string,
    value: any
}

export default function SegmentedSelector(props: SelectorProps) {

    const changeSelection = async (selText: string) => {
        const item = props.items.find(i => i.text === selText)

        if(item)
            props.onSelect(item)
    }

    return (
        <Select id={props.id} label={props.name} onChange={selValue => changeSelection(selValue)} value={props.selectedItem?.text}>
            {props.items.map(item =>
                <Option key={item.text} value={item.text}>{item.text}</Option>
            )}
        </Select>
    );
}

export function SegmentedSelectorA(props: SelectorProps) {
    return (
        <Tabs id={props.id} value="none" className={props.className}>
            <TabsHeader>
                {props.items.map(item => (
                    <Tab key={item.text} value={item.text} onClick={() => props.onSelect(item)}>
                        {item.text}
                    </Tab>
                ))}
            </TabsHeader>
            <TabsBody>
                <div></div>
            </TabsBody>
        </Tabs>
    );
}