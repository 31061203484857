import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Api } from "../Api";
import { Alert, Button, Typography } from "@material-tailwind/react";
import ErrorInformation from "./ErrorInformation";
import { PersonContext } from "../contexts/PersonContext";

export default function RedeemCodeFunnelSessionPage() {

    const [loading, setLoading] = useState(true)
    const [personContext] = useContext(PersonContext)
    const [responseFunnelSession, setResponseFunnelSession] = useState<Api.FunnelSession>()
    const [responseError, setResponseError] = useState<Error>()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    const redeem = async () => {
        if (personContext.loggedInPerson === null) {
            const currentPath = window.location.pathname + window.location.search
            console.log(`Current path is ${currentPath}`)
            navigate(`/person?m=login-req&r=${btoa(currentPath)}`);
            return
        }

        try {
            setLoading(true)

            const funnelSessionBase64 = searchParams.get('fs')
            const funnelSessionJson = atob(funnelSessionBase64)
            const funnelSession: Api.RequestFunnelSession = JSON.parse(funnelSessionJson)

            setResponseError(undefined)
            setResponseFunnelSession(await Api.SaveFunnelSession(funnelSession))
        } catch (error) {
            setResponseError(error)
        }

        setLoading(false)
    }

    useEffect(() => {
        redeem()    
    }, [])

    if (loading) {
        return (
            <div className="flex justify-center items-center h-full">
                <div className='w-8 h-8 mr-2 border-4 border-blue-400 border-t-transparent rounded-full animate-spin'></div>
            </div>
        )
    } else if (responseError) {
        return (
            <ErrorInformation error={responseError}
                onRetry={() => redeem()}
                message={responseError instanceof Api.FunnelSessionAlreadyRedeemedError ? 'Ergebnis kann nicht mehrfach eingetragen werden' : responseError.message} />
        )
    } else if (responseFunnelSession) {
        return (
            <div className="flex flex-col gap-2 items-center justify-center h-full p-4">
                <Alert color="green">Ergebnis erfolgreich gespeichert für {personContext.loggedInPerson?.name}</Alert>
                <Button variant="text" onClick={() => navigate('/score')}>Zurück zur Startseite</Button>
            </div>
        )
    } else {
        return (
            <Typography variant='h5'>Unbekannter Fehler</Typography>
        )
    }
}