import React, { useState } from "react";
import { Api } from "../Api";
import { Button } from "@material-tailwind/react";

export type RegisterPersonProps = {
    afterRegistered: (person: Api.Person) => void;
    onSwitchToConnect: () => void;
};

export default function RegisterPerson(props: RegisterPersonProps) {

    const [loading, setLoading] = useState(false);
    const [isNameAlreadyTaken, setNameAlreadyTaken] = useState(false);
    const [name, setName] = useState("");

    const register = async (name: string) => {
        if (loading || name.length < 3 || name.length > 30)
            return;

        try {
            setLoading(true);
            const person = await Api.RegisterPerson(name);
            props.afterRegistered(person);
        } catch (error) {
            if (error instanceof Api.NameAlreadyTakenError) {
                setNameAlreadyTaken(true);
            }
        }

        setLoading(false);
    };

    return (
        <div className='flex flex-col justify-center items-center'>
            <div className="w-full max-w-xs">
                <form className="bg-white">
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                            Name
                        </label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight"
                            id="name"
                            type="text"
                            placeholder="Name"
                            value={name}
                            onChange={e => { setName(e.target.value); setNameAlreadyTaken(false); }} />
                        <p className={`text-red-500 text-xs mt-1 ${isNameAlreadyTaken ? '' : 'hidden'}`}>Der Name ist bereits vergeben!</p>
                    </div>
                    <div className="flex items-center justify-between">
                        <Button disabled={loading || name.length < 3 || name.length > 30}
                            onClick={() => register(name)}
                            className='flex items-center'>

                            <div className={`inline-block w-3 h-3 mr-2 border-4 border-blue-200 border-t-transparent rounded-full animate-spin ${loading ? '' : 'hidden'}`}></div>
                            <span>Registrieren</span>
                        </Button>
                        <Button variant='text' onClick={() => props.onSwitchToConnect()}>
                            Bereits registriert?
                        </Button>
                    </div>
                </form>
            </div>
        </div>

    );
}