import { IconType } from "react-icons/lib";
import { Api } from "./Api";
import { MdFilter, MdFilterAlt, MdOutlineDelete, MdOutlineFilter, MdOutlineFilter1, MdOutlineFilterAlt, MdOutlineSportsBar } from "react-icons/md";
import colors from "tailwindcss/colors";

export function formatTime(milliseconds: number): string {
    try {
        const seconds = Math.floor(milliseconds / 1000);
        const remainingMilliseconds = milliseconds % 1000;

        return `${String(seconds).padStart(2, '0')}:${String(remainingMilliseconds).padStart(3, '0').substring(0, 2)}`;
    } catch (error) {
        return milliseconds.toString()
    }
}

export function timeout(delay: number) {
    return new Promise(res => setTimeout(res, delay));
}

export function getInitials(name: string): string {
    try {
        const { 0: first, length, [length - 1]: last } = name.split(' ')
        return `${first[0].toUpperCase()}${(length < 2 || last === undefined) ? '' : last[0].toUpperCase()}`
    } catch (error) {
        return name[0]
    }
}

export function formatLiters(milliliters: number): string {
    try {
        const liters = milliliters / 1000
        return liters.toFixed(2)
    } catch (error) {
        return milliliters.toString()
    }
}

export function formatMinutes(milliseconds: number): string {
    try {
        const minutes = Math.floor(milliseconds / 1000 / 60)
        const remainingMilliseconds = milliseconds % (1000 * 60)
        const seconds = Math.floor(remainingMilliseconds / 1000)
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
    } catch (error) {
        return milliseconds.toString()
    }
}

export function getMillilitersPerSecond(milliseconds: number, milliliters: number) {
    try {
        const seconds = milliseconds / 1000

        if (seconds === 0)
            return 0

        return Math.floor(milliliters / seconds)
    } catch (error) {
        return -1
    }
}

export function getIconForDrinkType(type: Api.DrinkSessionType): IconType {
    if (type === Api.DrinkSessionType.Ex) {
        return MdOutlineSportsBar
    } else if (type === Api.DrinkSessionType.Canning) {
        return MdOutlineDelete
    } else {
        return MdOutlineFilterAlt
    }
}

export function getColorForPlace(index: number): string {
    if (index === 0)
        return 'bg-yellow-600'
    else if (index <= 2)
        return 'bg-green-200'
    else if (index <= 4)
        return 'bg-blue-200'
    else
        return 'bg-gray-300'
}