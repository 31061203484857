import { Dispatch, SetStateAction, createContext } from "react";
import { Api } from "../Api";

export type PersonContextType = {
    loggedInPerson?: Api.Person | null;
}

export const PersonContext = createContext<[PersonContextType, Dispatch<SetStateAction<PersonContextType>>]>([
    null,
    () => {}
]);