import React, { useCallback, useContext, useEffect, useState } from 'react';
import { MdPlayArrow, MdSave, MdStop } from 'react-icons/md'
import SegmentedSelector, { SegmentedSelectorA, SelectorItem } from './SegmentedSelector';
import { TimerContext, TimerState } from '../contexts/TimerContext';
import { Button, ButtonGroup, Typography } from '@material-tailwind/react';
import { useNavigate } from 'react-router-dom';
import { formatTime } from '../Util';
import { Api } from '../Api';

export const drinkCategories: SelectorItem[] = [
    { text: '0,33l', value: 330 },
    { text: '0,5l', value: 500 },
    { text: '0,66l', value: 660 },
    { text: '1,00l', value: 1000 }
]

const drinkSessionTypes: SelectorItem[] = [
    { text: 'Exen', value: Api.DrinkSessionType.Ex },
    { text: 'Dosenstechen', value: Api.DrinkSessionType.Canning },
    { text: 'Trichtern', value: Api.DrinkSessionType.Funneling }
]

export default function MeasurePage() {
    const [formattedTime, setFormattedTime] = useState("00:00");
    const [manualOverrideOn, setManualOverrideOn] = useState(false);
    const [timerContext, setTimerContext] = useContext(TimerContext);
    const navigate = useNavigate();

    const startTimer = useCallback(() => {
        const time = Date.now()
        setTimerContext({ ...timerContext, startDate: time, state: TimerState.Running })
    }, [timerContext])

    const stopTimer = useCallback(() => {
        const time = Date.now();
        const milliseconds = time - timerContext.startDate;

        if (milliseconds < 200 || timerContext.endDate !== -1)
            return;

        setTimerContext({ ...timerContext, endDate: time, state: TimerState.Ended });
    }, [timerContext])

    const updateView = useCallback(() => {
        let milliseconds: number

        if (timerContext.startDate === -1) {
            milliseconds = 0
        } else if (timerContext.endDate === -1) {
            milliseconds = Date.now() - timerContext.startDate
        } else {
            milliseconds = timerContext.endDate - timerContext.startDate
        }

        setFormattedTime(formatTime(milliseconds));
    }, [timerContext])

    const timeManualAdd = useCallback((amount: number) => {
        const endTime = Math.max(timerContext.startDate, timerContext.endDate + amount)
        const updatedManualOffset = timerContext.manualOffset + amount
        setTimerContext({ ...timerContext, endDate: endTime, manualOffset: updatedManualOffset });
    }, [timerContext])

    useEffect(() => {
        updateView()

        if (timerContext.state !== TimerState.Running) {
            return () => { };
        }

        const timer = setInterval(() => updateView(), 10)

        return () => clearInterval(timer)
    }, [timerContext])

    const manualOverrideButtonVisible = timerContext.state === TimerState.Ended && !manualOverrideOn;

    return (
        <div className='flex flex-col justify-between items-stretch h-full'>
            <Button variant='text' className={`self-end m-4 ${manualOverrideButtonVisible ? '' : 'invisible'}`}
                onClick={() => setManualOverrideOn(true)}>
                Vertippt?
            </Button>

            <div className='flex flex-col justify-center items-center'>
                <Typography variant='lead'>Aufzeichnen</Typography>

                <Typography variant='h1' className='font-bold font-mono'>{formattedTime}</Typography>

                <ButtonGroup className={`mt-2 ${manualOverrideOn ? '' : 'invisible'}`}>
                    <Button onClick={() => timeManualAdd(-100)}>
                        <Typography variant="h5">−</Typography>
                    </Button>
                    <Button onClick={() => timeManualAdd(100)}>
                        <Typography variant="h5">+</Typography>
                    </Button>
                </ButtonGroup>
            </div>

            <ControlPanel timerState={timerContext.state}
                start={() => startTimer()}
                stop={() => stopTimer()}
                save={() => navigate("/trichtern/save")}
                selectedAmount={timerContext.amountMilliliter}
                onSelectAmount={amount => setTimerContext({ ...timerContext, amountMilliliter: amount })}
                selectedDrinkSessionType={timerContext.drinkSessionType}
                onSelectDrinkSessionType={type => setTimerContext({ ...timerContext, drinkSessionType: type })} />
        </div>
    );
}

function ControlPanel(props: {
    timerState: TimerState,
    start: () => void,
    stop: () => void,
    save: () => void,
    selectedAmount: number,
    onSelectAmount: (amount: number) => void,
    selectedDrinkSessionType?: Api.DrinkSessionType,
    onSelectDrinkSessionType: (type: Api.DrinkSessionType) => void
}) {
    const buttonClasses = 'flex justify-center items-center gap-2 w-full'

    return (
        <div className='m-4'>
            <SegmentedSelectorA name='' id='measure_first_type' className='mb-2' items={drinkSessionTypes} selectedItem={undefined} onSelect={item => { props.onSelectDrinkSessionType(item.value as Api.DrinkSessionType) }} />

            <SegmentedSelectorA name='' id='measure_sec_amount' className='mb-4' items={drinkCategories} selectedItem={undefined} onSelect={item => { props.onSelectAmount(item.value as number) }} />

            {props.timerState === TimerState.Idle &&
                <Button color='green' className={buttonClasses} onClick={props.start} disabled={props.selectedAmount === 0}>
                    <MdPlayArrow size={24} />
                    <Typography>Start</Typography>
                </Button>
            }

            {props.timerState === TimerState.Running &&
                <Button color='red' className={buttonClasses} onClick={props.stop}>
                    <MdStop size={24} />
                    <Typography>Stop</Typography>
                </Button>
            }

            {props.timerState === TimerState.Ended &&
                <Button color='yellow' className={buttonClasses} onClick={props.save} disabled={props.selectedAmount === 0}>
                    <MdSave size={24} />
                    <Typography>Ergebnis speichern</Typography>
                </Button>
            }
        </div>
    );
}