import { Dispatch, SetStateAction, createContext } from "react";
import { Api } from "../Api";

export enum TimerState {
    Idle,
    Running,
    Ended
}

export type TimerContextType = {
    startDate: number;
    endDate: number;
    state: TimerState;
    manualOffset: number;
    amountMilliliter: number;
    drinkSessionType: Api.DrinkSessionType
}

export const TimerContext = createContext<[TimerContextType, Dispatch<SetStateAction<TimerContextType>>]>([
    { startDate: -1, endDate: -1, state: TimerState.Idle, manualOffset: 0, amountMilliliter: 0, drinkSessionType: Api.DrinkSessionType.Funneling },
    () => {}
]);