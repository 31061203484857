import React, { useContext, useEffect, useState } from "react";
import { Api } from "../Api";
import { Button, List, ListItem, ListItemPrefix, Typography } from "@material-tailwind/react";
import { getInitials } from "../Util";

export type ConnectPersonProps = {
    afterConnected: (person: Api.Person) => void;
    onSwitchToRegister: () => void;
}

export default function ConnectPerson(props: ConnectPersonProps) {

    const [loading, setLoading] = useState(false);
    const [personList, setPersonList] = useState<Api.PersonList>();

    const loadPersons = async () => {
        const personList = await Api.GetAllPersons();
        setPersonList(personList);
    }

    const loadData = async () => {
        setLoading(true)
        try {
            await loadPersons()
        } finally {
            setLoading(false)
        }
    }

    const selectPerson = async (person: Api.Person) => {
        setLoading(true);
        try {
            await Api.ConnectPerson(person.id);
            props.afterConnected(person)
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadData()
    }, [])

    return (
        <div className="flex flex-col gap-4 w-full">
            <Button variant='text' className='p-4' onClick={() => props.onSwitchToRegister()}>
                Neu registrieren?
            </Button>
            <ConnectPersonContent
                loading={loading}
                personList={personList}
                onPersonSelected={person => selectPerson(person)} />
        </div>

    );
}

function ConnectPersonContent(props: {
    loading: boolean,
    personList: Api.PersonList,
    onPersonSelected: (person: Api.Person) => void
}) {
    if (props.loading) {
        return (
            <div className="flex justify-center items-center h-full">
                <div className='w-8 h-8 mr-2 border-4 border-blue-400 border-t-transparent rounded-full animate-spin'></div>
            </div>
        );
    } else if (props.personList) {
        return <ChoosePersonList
            personList={props.personList}
            onPersonSelected={props.onPersonSelected} />;
    } else {
        return (
            <div className="flex flex-col justify-center items-center h-full">
                <p className="text-center">Es ist ein Fehler aufgetreten</p>
                <p className="text-center">Lade die Seite neu</p>
            </div>
        );
    }
};

export function ChoosePersonList(props: {
    personList: Api.PersonList,
    onPersonSelected: (person: Api.Person) => void
}) {
    return (
        <List className='w-full'>
            {props.personList.persons.map((person: Api.Person) =>
                <ListItem key={person.id} onClick={() => props.onPersonSelected(person)}>
                    <ListItemPrefix>
                        <div className='inline-flex items-center justify-center w-10 h-10 rounded-full bg-gray-300'>
                            <span>{getInitials(person.name)}</span>
                        </div>
                    </ListItemPrefix>
                    <div>
                        <Typography variant="h6" color="blue-gray">
                            {person.name}
                        </Typography>
                    </div>
                </ListItem>
            )}
        </List>
    );
}