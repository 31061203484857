import React, { useCallback, useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ConnectPerson from "./ConnectPerson";
import RegisterPerson from "./RegisterPerson";
import { PersonContext } from "../contexts/PersonContext";
import { Api } from "../Api";
import { Typography } from "@material-tailwind/react";

export default function PersonPage() {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [personContext, setPersonContext] = useContext(PersonContext)
    const [action, setAction] = useState(1)
    const redirectPathBase64 = searchParams.get("r");
    const messageType = searchParams.get("m");

    const setPersonAndNavigate = useCallback((person: Api.Person, redirectPathBase64: string) => {
        setPersonContext({ loggedInPerson: person })

        if (redirectPathBase64)
            navigate(atob(redirectPathBase64))
        else
            navigate('/score')
    }, [personContext])

    const innerComponent = action === 0
        ? <ConnectPerson afterConnected={person => setPersonAndNavigate(person, redirectPathBase64)} onSwitchToRegister={() => setAction(1)} />
        : <RegisterPerson afterRegistered={person => setPersonAndNavigate(person, redirectPathBase64)} onSwitchToConnect={() => setAction(0)} />

    let messageComponent = <div></div>;

    if (messageType === 'login-req') {
        messageComponent = <Typography color='red' className='pt-4'>Du musst dich zuerst anmelden</Typography>
    } else if (messageType === 'success-auth') {
        messageComponent = <Typography color='green' className='pt-4'>Erfolgreich autorisiert. Melde dich an</Typography>
    }

    return (
        <div className='flex flex-col h-full gap-4 items-center justify-center'>
            {messageComponent}
            {innerComponent}
        </div>
    )
}