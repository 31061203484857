import { Button, Card, CardBody, CardFooter, Typography } from "@material-tailwind/react";
import React, { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import QRDisplay from "./QRDisplay";
import { MdCheck } from "react-icons/md";

export default function QRPage() {

    const [searchParams] = useSearchParams()
    const [copied, setCopied] = useState(false)

    const getContent = useCallback((): string => {
        const contentBase64 = searchParams.get("c")
        const content = atob(contentBase64)
        console.log(content)
        return content
    }, [searchParams])

    const copyLinkToClipboard = (contentString: string) => {
        navigator.clipboard.writeText(contentString)
        setCopied(true)
    }

    const content = getContent()

    return (
        <div className="flex flex-col justify-center items-center h-full p-4">
            <Card className="w-full shadow-all">
                <CardBody>
                    <QRDisplay codeContent={content} />
                </CardBody>
                <CardFooter>
                    <div className="flex flex-col items-center gap-6">
                        <Typography variant='h5' className='text-center'>QR-Code scannen</Typography>
                        <Button className='flex items-center gap-4' onClick={() => copyLinkToClipboard(content)}>Link zum Versenden kopieren {copied && <MdCheck size={20}/>}</Button>
                    </div>
                </CardFooter>
            </Card>
        </div>
    )
}