import React, { useEffect, useState } from "react";
import { Api } from "../Api";
import { Navigate } from "react-router-dom";
import ErrorInformation from "./ErrorInformation";

export default function InvitationPage() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error>();
    const [invitation, setInvitation] = useState<Api.Invitation>()

    const retrieveInvitationForUser = async () => {
        try {
            setLoading(true)
            const invitation = await Api.CreateInvitation()
            setError(undefined)
            setInvitation(invitation)
        } catch (error) {
            setError(error)
        }

        setLoading(false)
    }

    useEffect(() => {
        retrieveInvitationForUser()
    }, [])

    if (loading) {
        return (
            <div className="flex justify-center items-center h-full">
                <div className='w-8 h-8 mr-2 border-4 border-blue-400 border-t-transparent rounded-full animate-spin'></div>
            </div>
        )
    } else if (invitation) {
        const redeemUrl = `${window.location.origin}/redeeminvite?inv=${btoa(JSON.stringify(invitation))}`
        const content = btoa(redeemUrl)

        console.log(redeemUrl)

        return <Navigate to={`/qr?c=${content}`} replace />
    } else if (error) {
        return (
            <ErrorInformation error={error}
                onRetry={() => retrieveInvitationForUser()} />
        )
    }
}