import { ReactElement, useContext } from "react";
import { PersonContext } from "../contexts/PersonContext";
import { Navigate } from "react-router-dom";
import React from "react";

export default function EnsurePerson(props: { children: ReactElement }) {

    const [personContext] = useContext(PersonContext)

    if (personContext.loggedInPerson === null) {
        return <Navigate to={'/person?m=login-req'} replace />
    } else {
        return props.children
    }
}