import React, {useEffect, useState} from 'react';
import {Route, Routes} from 'react-router-dom';
import AppRoutes from './AppRoutes';
import {Layout} from './components/Layout';
import {TimerContext, TimerContextType, TimerState} from './contexts/TimerContext';
import './custom.css';
import './styles.css';
import {PersonContext, PersonContextType} from './contexts/PersonContext';
import {Api} from './Api';
import {AuthContext, AuthContextType} from './contexts/AuthContext';
import ErrorInformation from './components/ErrorInformation';

export default function App() {

    const [authContext, setAuthContext] = useState<AuthContextType>()
    const [personContext, setPersonContext] = useState<PersonContextType>();
    const [manualTimerEnabled, setManualTimerEnabled] = useState<boolean | undefined>(undefined)
    const timerContext = useState<TimerContextType>(
        {
            startDate: -1,
            endDate: -1,
            manualOffset: 0,
            state: TimerState.Idle,
            amountMilliliter: 0,
            drinkSessionType: Api.DrinkSessionType.Funneling
        });

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<Error>()

    const loadAuthContext = async () => {
        try {
            const role = await Api.GetCurrentRole();
            setAuthContext({currentRole: role.role});
        } catch (error) {
            setAuthContext({currentRole: null});
        }
    }

    const loadPersonContext = async () => {
        try {
            const person = await Api.GetMe();
            setPersonContext({loggedInPerson: person});
        } catch (error) {
            setPersonContext({loggedInPerson: null});
        }
    }

    const loadManualTimerEnabled = async (): Promise<void> => {
        try {
            const manualTimerEnabled = await Api.GetManualTimerEnabled()
            setManualTimerEnabled(manualTimerEnabled)
        } catch (error) {
            setManualTimerEnabled(true)
        }
    }

    const loadPrerequisites = async () => {
        try {
            setLoading(true)
            await loadAuthContext()
            await loadPersonContext()
            await loadManualTimerEnabled()
        } catch (error) {
            setError(error)
        }
        setLoading(false)
    }

    useEffect(() => {
        loadPrerequisites()
    }, [])

    let content;

    if (loading || authContext === undefined || personContext === undefined || manualTimerEnabled === undefined) {
        content = (
            <div className="flex justify-center items-center h-screen">
                <div
                    className='w-8 h-8 mr-2 border-4 border-blue-400 border-t-transparent rounded-full animate-spin'></div>
            </div>
        )
    } else if (error) {
        content = <ErrorInformation error={error}/>
    } else {
        content = (
            <Layout isManualTimerEnabled={manualTimerEnabled}>
                <Routes>
                    {AppRoutes.map((route, index) => {
                        const {element, ...rest} = route;
                        return <Route key={index} {...rest} element={element}/>;
                    })}
                </Routes>
            </Layout>
        )
    }

    return (
        <AuthContext.Provider value={[authContext, setAuthContext]}>
            <PersonContext.Provider value={[personContext, setPersonContext]}>
                <TimerContext.Provider value={timerContext}>
                    {content}
                </TimerContext.Provider>
            </PersonContext.Provider>
        </AuthContext.Provider>
    );
}
