import { Button, Typography } from "@material-tailwind/react";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function PreInvitationPage() {
    const navigate = useNavigate()

    return (
        <div className='h-full flex flex-col justify-center items-center gap-6 p-4 text-center'>
            <Typography variant='h3'>Neuen Nutzer ins Camp einladen?</Typography>
            <Typography variant='lead' color='red'>Dieser kann dann beliebig Einträge erstellen</Typography>
            <Button onClick={() => navigate('/invite/camp')}>Einladen</Button>
        </div>
    )
}