import {useCallback, useContext, useState} from "react";
import {TimerContext, TimerState} from "../contexts/TimerContext";
import {Button, Card, CardBody, CardFooter, List, ListItem, ListItemPrefix, Typography} from "@material-tailwind/react";
import React from "react";
import {PersonContext} from "../contexts/PersonContext";
import {formatTime, getInitials} from "../Util";
import {Api} from "../Api";
import {useNavigate} from "react-router-dom";
import {v4 as uuidv4} from "uuid"

function formatMilliliter(amount: number): string {
    const liters = amount / 1000;
    return `${liters}l`
}

export default function SaveTimerPage() {

    const navigate = useNavigate()
    const [timerContext, setTimerContext] = useContext(TimerContext)
    const [personContext] = useContext(PersonContext)
    const [selectedOption, setSelectedOption] = useState(1)
    const [loading, setLoading] = useState(false)

    const saveForCurrentUser = async () => {
        try {
            setLoading(true);
            const token = await Api.CreateFunnelSessionToken()

            await Api.SaveFunnelSession({
                token: token,
                type: timerContext.drinkSessionType,
                milliliters: timerContext.amountMilliliter,
                totalMilliseconds: timerContext.endDate - timerContext.startDate,
                correctedMilliseconds: timerContext.manualOffset,
                recordedByPersonId: null,
                recordedExternally: false,
                uniqueId: uuidv4(),
                drinkingPersonId: null
            })

            setTimerContext(
                {
                    startDate: -1,
                    endDate: -1,
                    manualOffset: 0,
                    state: TimerState.Idle,
                    amountMilliliter: 0,
                    drinkSessionType: Api.DrinkSessionType.Funneling
                })

            navigate('/me')
        } finally {
            setLoading(false);
        }
    }

    const createCodeForOtherUser = async () => {
        if (personContext.loggedInPerson === null)
            return

        try {
            setLoading(true);

            const token = await Api.CreateFunnelSessionToken()

            const requestFunnelSession: Api.RequestFunnelSession = {
                token: token,
                type: timerContext.drinkSessionType,
                milliliters: timerContext.amountMilliliter,
                totalMilliseconds: timerContext.endDate - timerContext.startDate,
                correctedMilliseconds: timerContext.manualOffset,
                recordedByPersonId: personContext.loggedInPerson?.id,
                recordedExternally: false,
                uniqueId: uuidv4(),
                drinkingPersonId: null
            }

            const redeemUrl = `${window.location.origin}/redeem?fs=${btoa(JSON.stringify(requestFunnelSession))}`
            const content = btoa(redeemUrl)

            console.log(redeemUrl)

            setTimerContext(
                {
                    startDate: -1,
                    endDate: -1,
                    manualOffset: 0,
                    state: TimerState.Idle,
                    amountMilliliter: 0,
                    drinkSessionType: Api.DrinkSessionType.Funneling
                })

            navigate(`/qr?c=${content}`);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="flex flex-col justify-center items-center h-full p-4">
            <Card className="w-full shadow-all">
                <CardBody>
                    <Typography variant='h5' className='mb-2'>
                        {formatMilliliter(timerContext.amountMilliliter)} in {formatTime(timerContext.endDate - timerContext.startDate)} Sekunden
                        getrichtert!
                    </Typography>

                    <List>
                        <ListItem selected={selectedOption === 0}
                                  disabled={personContext.loggedInPerson === null}
                                  onClick={() => setSelectedOption(0)}>
                            <ListItemPrefix>
                                <div
                                    className='inline-flex items-center justify-center w-10 h-10 rounded-full bg-gray-300'>
                                    <span>{getInitials(personContext.loggedInPerson?.name ?? 'X')}</span>
                                </div>
                            </ListItemPrefix>
                            <div>
                                <Typography variant="h6" color="blue-gray">
                                    Für mich
                                </Typography>
                                <Typography variant="small" color="gray" className="font-normal">
                                    {personContext.loggedInPerson?.name ?? ''}
                                </Typography>
                            </div>
                        </ListItem>
                        <ListItem selected={selectedOption === 1}
                                  onClick={() => setSelectedOption(1)}>
                            <ListItemPrefix>
                                <div
                                    className='inline-flex items-center justify-center w-10 h-10 rounded-full bg-gray-300'></div>
                            </ListItemPrefix>
                            <div>
                                <Typography variant="h6" color="blue-gray">
                                    Für jemand anderen
                                </Typography>
                            </div>
                        </ListItem>
                    </List>
                </CardBody>
                <CardFooter>
                    <Button disabled={loading}
                            onClick={() => selectedOption === 0 ? saveForCurrentUser() : createCodeForOtherUser()}>
                        <div
                            className={`inline-block w-3 h-3 mr-2 border-4 border-blue-200 border-t-transparent rounded-full animate-spin ${loading ? '' : 'hidden'}`}></div>
                        <span>{selectedOption === 0 ? 'Speichern' : 'Code erzeugen'}</span>
                    </Button>
                </CardFooter>
            </Card>
        </div>
    );
}