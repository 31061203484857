import {Button, Card, CardBody, List} from "@material-tailwind/react";
import React, {useEffect, useState} from "react";
import {Api} from "../Api";
import ErrorInformation from "./ErrorInformation";
import {StatHistoryEntryPersonDetails} from "./StatEntry";

export default function SettingsPage() {

    const [stats, setStats] = useState<Api.GlobalFunnelStats>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<Error>()

    const revokeAuthorization = async () => {
        try {
            setLoading(true)
            setError(undefined)
            await Api.RevokeGlobalAuthorization()
        } catch (error) {
            setError(error)
        }

        setLoading(false)
    }

    const deleteEntry = async (funnelSessionId: number) => {
        try {
            setLoading(true)
            setError(undefined)
            await Api.DeleteFunnelSession(funnelSessionId)
            await loadStats()
        } catch (error) {
            setError(error)
        }

        setLoading(false)
    }

    const loadStats = async () => {
        try {
            setLoading(true)

            console.log('Requesting stats for settings')

            const stats = await Api.GetGlobalStats()
            setError(undefined)
            setStats(stats)
        } catch (error) {
            setError(error)
        }

        setLoading(false)
    }

    const setCurrentLocation = async () => {
        try {
            navigator.geolocation.getCurrentPosition(
                pos => setLocationTo({latitude: pos.coords.latitude, longitude: pos.coords.longitude}),
                err => setError(new Error(JSON.stringify(err))),
                {enableHighAccuracy: true})
        } catch (error) {
            setError(error)
        }
    }

    const setLocationTo = async (location: Api.Location) => {
        try {
            setLoading(true)
            await Api.SetLocation(location)
            setError(undefined)
            setStats(stats)
        } catch (error) {
            setError(error)
        }

        setLoading(false)
    }

    const setManualTimerEnabled = async (enabled: boolean): Promise<void> => {
        try {
            setLoading(true)
            await Api.SetManualTimerEnabled(enabled)
            setError(undefined)
            setStats(stats)
        } catch (error) {
            setError(error)
        }

        setLoading(false)
    }

    useEffect(() => {
        loadStats()
    }, [])

    let statsContent;

    if (loading) {
        statsContent = (
            <div className="flex justify-center items-center h-20">
                <div
                    className='w-8 h-8 mr-2 border-4 border-blue-400 border-t-transparent rounded-full animate-spin'></div>
            </div>
        )
    } else if (error) {
        statsContent = (
            <ErrorInformation error={error} onRetry={() => loadStats()}/>
        )
    } else if (stats) {
        statsContent = (
            <List>
                {stats.historyFunnelSessions.map(fs =>
                    <StatHistoryEntryPersonDetails key={fs.id} fs={fs} onDelete={fs => deleteEntry(fs.id)}/>
                )}
            </List>
        )
    }

    return (
        <div className='flex flex-col p-4 gap-4'>
            <Card>
                <CardBody className='flex flex-col justify-center'>
                    <Button color='red' variant='outlined' onClick={() => revokeAuthorization()}>Autorisierung global
                        zurücksetzen</Button>

                    <Button variant='outlined' className='mt-8' onClick={() => setCurrentLocation()}>Position
                        setzen</Button>
                </CardBody>
            </Card>
            <Card>
                <CardBody className='flex flex-row justify-center'>
                    <Button color='green' variant='outlined' onClick={() => setManualTimerEnabled(true)}>Manueller Timer
                        an</Button>

                    <Button color='gray' variant='outlined' className='ml-4'
                            onClick={() => setManualTimerEnabled(false)}>Manueller Timer aus</Button>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    {statsContent}
                </CardBody>
            </Card>
        </div>
    )
}