export module Api {

    export enum DrinkSessionType {
        Ex = 0,
        Canning = 1,
        Funneling = 2
    }

    export type Role = {
        role?: string | null
    }

    export type ManualTimerEnabled = {
        isEnabled: boolean
    }

    export type Location = {
        latitude: number,
        longitude: number
    }

    export type Invitation = {
        invitedByPersonId: number;
        token: string;
    }

    export type Person = {
        id: number;
        name: string;
        registeredOn: Date;
    }

    export type PersonList = {
        persons: Person[]
    }

    export type RequestFunnelSession = {
        token: string,
        type: DrinkSessionType,
        milliliters: number,
        totalMilliseconds: number,
        correctedMilliseconds: number,
        recordedByPersonId?: number | null,
        recordedExternally: boolean,
        uniqueId: string,
        drinkingPersonId: number | null
    }

    export type FunnelSession = {
        id: number,
        type: DrinkSessionType,
        milliliters: number,
        totalMilliseconds: number,
        correctedMilliseconds: number,
        timestamp: Date,
        drinkingPerson?: Person | null,
        recordedExternally: boolean
    }

    export type FunnelStats = {
        totalMilliliters: number,
        totalMilliseconds: number,
        averageMilliliters: number,
        funnelSessions: FunnelSession[]
    }

    export type GlobalPersonStats = {
        person: Person,
        totalMilliliter: number,
        totalMilliseconds: number,
        funnelSessionCount: number
    }

    export type GlobalFunnelStats = {
        totalMilliliters: number,
        totalMilliseconds: number,
        averageMilliliters: number,
        slowestFunnelSession?: FunnelSession | null,
        fastestFunnelSession?: FunnelSession | null,
        historyFunnelSessions: FunnelSession[],
        personStats: GlobalPersonStats[]
    }

    export class NameAlreadyTakenError extends Error {
    }

    export class FunnelSessionAlreadyRedeemedError extends Error {
    }

    export class AccessDeniedError extends Error {
    }

    export async function GetCurrentRole(): Promise<Role> {
        try {
            const options = {method: 'GET'};
            const response = await fetch('Api/Authenticate/Role', options);
            const data: Role = await response.json();
            return data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    export async function CreateInvitation(): Promise<Invitation> {
        try {
            const options = {method: 'POST'};
            const response = await fetch('Api/Authenticate/CreateInvitation', options);
            const data: Invitation = await response.json();
            return data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    export async function RedeemInvitation(invitation: Invitation): Promise<any> {
        try {
            const options = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(invitation)
            }

            const response = await fetch('Api/Authenticate/RedeemInvitation', options)

            if (response.status !== 200)
                throw Error('Could not authenticate')
        } catch (error) {
            console.log(error)
            throw error
        }
    }

    export async function RegisterPerson(name: string): Promise<Person> {
        try {
            const options = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({"name": name})
            };
            const response = await fetch('Api/Person/Register', options);

            if (response.status === 409) {
                throw new NameAlreadyTakenError();
            }

            const data: Person = await response.json();
            return data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    export async function ConnectPerson(id: number): Promise<Person> {
        try {
            const options = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({"personId": id})
            };
            const response = await fetch('Api/Person/Connect', options);
            const data: Person = await response.json();
            return data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    export async function GetAllPersons(): Promise<PersonList> {
        try {
            const options = {method: 'GET'};
            const response = await fetch('Api/Person/All', options);
            const data: PersonList = await response.json();
            return data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    export async function GetMe(): Promise<Person | null> {
        try {
            const options = {method: 'GET'};
            const response = await fetch('Api/Person/Me', options);

            if (response.status === 404) {
                return null;
            }

            const data: Person = await response.json();
            return data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    export async function CreateFunnelSessionToken(): Promise<string> {
        try {
            const options = {method: 'POST'};
            const response = await fetch('Api/FunnelSession/CreateToken', options);

            if (response.status === 401 || response.status === 403) {
                throw new AccessDeniedError()
            }

            const token: string = await response.text();
            return token;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    export async function SaveFunnelSession(request: RequestFunnelSession): Promise<FunnelSession> {
        try {
            const options = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    token: request.token,
                    type: request.type,
                    milliliters: request.milliliters,
                    totalMilliseconds: request.totalMilliseconds,
                    correctedMilliseconds: request.correctedMilliseconds,
                    recordedByPersonId: request.recordedByPersonId,
                    recordedExternally: request.recordedExternally,
                    uniqueId: request.uniqueId,
                    drinkingPersonId: request.drinkingPersonId
                })
            };

            const response = await fetch('Api/FunnelSession/Create', options);

            if (response.status === 409) {
                throw new FunnelSessionAlreadyRedeemedError()
            }

            if (response.status > 299) {
                throw new Error()
            }

            const data: FunnelSession = await response.json();
            return data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    export async function GetPersonStats(date?: Date | null, drinkType?: DrinkSessionType | null, drinkCategory?: number | null): Promise<FunnelStats> {
        try {
            const body = JSON.stringify({
                date: date,
                type: drinkType,
                category: drinkCategory
            })

            console.log('Starting persons stats request with ' + body)

            const options = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: body
            };

            const response = await fetch('Api/FunnelSession/GetPerson', options);

            if (response.status !== 200) {
                throw new AccessDeniedError()
            }

            const data: FunnelStats = await response.json();
            return data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    export async function GetGlobalStats(date?: Date | null, drinkType?: DrinkSessionType | null, drinkCategory?: number | null): Promise<GlobalFunnelStats> {
        try {
            const body = JSON.stringify({
                date: date,
                type: drinkType,
                category: drinkCategory
            })

            console.log('Starting global stats request with ' + body)

            const options = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: body
            };

            const response = await fetch('Api/FunnelSession/GetGlobal', options);
            const data: GlobalFunnelStats = await response.json();
            return data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    export async function RevokeGlobalAuthorization(): Promise<void> {
        try {
            const options = {method: 'DELETE'};
            const response = await fetch('Api/Authenticate/ResetGlobal', options);

            if (response.status !== 200)
                throw new Error()

        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    export async function DeleteFunnelSession(funnelSessionId: number): Promise<void> {
        try {
            const options = {method: 'DELETE'};
            const response = await fetch(`Api/FunnelSession/DeleteSingle/${funnelSessionId}`, options);

            if (response.status !== 200)
                throw new Error()

        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    export async function SetManualTimerEnabled(enabled: boolean): Promise<void> {
        try {
            const body: ManualTimerEnabled = {isEnabled: enabled}

            const options = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(body)
            };
            const response = await fetch('Api/Config/ManualTimerEnabled', options);

            if (response.status !== 200)
                throw new Error()

        } catch (error) {
            console.log(error)
            throw error;
        }
    }

    export async function GetManualTimerEnabled(): Promise<boolean> {
        try {
            const options = {method: 'GET'};
            const response = await fetch('Api/Config/ManualTimerEnabled', options);

            if (response.status !== 200)
                throw new Error()

            const data: ManualTimerEnabled = await response.json()
            return data.isEnabled
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    export async function SetLocation(location: Location): Promise<void> {
        try {
            const options = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(location)
            };
            const response = await fetch('Api/Config/Location', options);

            if (response.status !== 200)
                throw new Error()

        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    export async function GetLocation(): Promise<Location> {
        try {
            const options = {method: 'GET'};
            const response = await fetch('Api/Config/Location', options);

            if (response.status !== 200)
                throw new Error()

            const data: Location = await response.json()
            return data
        } catch (error) {
            console.log(error);
            throw error;
        }
    }
}