import { useContext, useEffect, useState } from "react";
import { Api } from "../Api";
import { Card, CardBody, List, Typography } from "@material-tailwind/react";
import React from "react";
import ErrorInformation from "./ErrorInformation";
import { PersonContext } from "../contexts/PersonContext";
import { MdLocalDrink, MdNumbers, MdSpeed, MdTimer } from "react-icons/md";
import { formatLiters, getMillilitersPerSecond, formatMinutes } from "../Util";
import SegmentedSelector, { SelectorItem } from "./SegmentedSelector";
import { EventStartDate, daySelectorItems, drinkCategoryItemsWithNone, drinkSessionTypeSelectorItemsWithNone } from "../Constants";
import { StatEntry, StatHistoryEntry } from "./StatEntry";

export default function PersonScorePage() {

    const [personContext] = useContext(PersonContext)
    const [stats, setStats] = useState<Api.FunnelStats>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<Error>()
    const [selectedDay, setSelectedDay] = useState<SelectorItem>()
    const [selectedDrinkType, setSelectedDrinkType] = useState<SelectorItem>()
    const [selectedDrinkCategory, setSelectedDrinkCategory] = useState<SelectorItem>()

    const loadStats = async () => {
        try {
            setLoading(true)

            let resultDate: Date = null

            if (selectedDay !== undefined && selectedDay?.value >= 0) {
                resultDate = new Date(EventStartDate)
                resultDate.setDate(resultDate.getDate() + (selectedDay.value as number))
            }

            console.log('Requesting person stats for date: ' + resultDate)

            const stats = await Api.GetPersonStats(
                resultDate,
                selectedDrinkType ? selectedDrinkType.value : null,
                selectedDrinkCategory ? selectedDrinkCategory.value : null)

            setError(undefined)
            setStats(stats)
        } catch (error) {
            setError(error)
        }

        setLoading(false)
    }

    useEffect(() => {
        loadStats()
    }, [selectedDay, selectedDrinkType, selectedDrinkCategory])

    if (loading) {
        return (
            <div className="flex justify-center items-center h-full">
                <div className='w-8 h-8 mr-2 border-4 border-blue-400 border-t-transparent rounded-full animate-spin'></div>
            </div>
        )
    } else if (stats) {
        return (
            <div className='flex flex-col p-4 gap-2'>
                <Typography variant='h3' className='text-center'>{`Willkommen ${personContext.loggedInPerson?.name}`}</Typography>

                <Card>
                    <CardBody className='flex flex-col gap-2'>
                        <Typography variant='lead'>Filter</Typography>

                        <SegmentedSelector id='day_select_person' name='Tag' items={daySelectorItems} selectedItem={selectedDay} onSelect={item => setSelectedDay(item)} />
                        <SegmentedSelector id='type_select_global' name='Typ' items={drinkSessionTypeSelectorItemsWithNone} selectedItem={selectedDrinkType} onSelect={item => setSelectedDrinkType(item)} />
                        <SegmentedSelector id='cat_select_global' name='Größe' items={drinkCategoryItemsWithNone} selectedItem={selectedDrinkCategory} onSelect={item => setSelectedDrinkCategory(item)} />
                    </CardBody>
                </Card>

                <Card className="shadow-all">
                    <CardBody className='flex flex-col gap-2'>
                        <Typography variant='lead' className='mb-2'>Deine Statistik</Typography>
                        <StatEntry icon={MdLocalDrink} text={`${formatLiters(stats.totalMilliliters)} Liter in`} />
                        <StatEntry icon={MdTimer} text={`${formatMinutes(stats.totalMilliseconds)} Minuten vernichtet`} />
                        <StatEntry icon={MdSpeed} text={`Das entspricht ${getMillilitersPerSecond(stats.totalMilliseconds, stats.totalMilliliters)} ml/s`} />
                        <StatEntry icon={MdNumbers} text={`Insgesamt ${stats.funnelSessions.length} mal ⌀ ${stats.averageMilliliters} ml`} />
                    </CardBody>
                </Card>

                <Card className="shadow-all">
                    <CardBody className='flex flex-col gap-2'>
                        <Typography variant='lead' className='mb-2'>Historie</Typography>
                        {stats.funnelSessions.length > 0 &&
                            <List>
                                {stats.funnelSessions.map(fs =>
                                    <StatHistoryEntry key={fs.id} fs={fs} />
                                )}
                            </List>
                        }
                        {stats.funnelSessions.length === 0 &&
                            <Typography>Keine Einträge</Typography>
                        }
                    </CardBody>
                </Card>
            </div>
        )
    } else if (error) {
        return (
            <ErrorInformation error={error}
                onRetry={() => loadStats()} />
        )
    }
}